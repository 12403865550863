'use client';
import React, { useState, useEffect } from "react";
import axios from "axios";
import Image from "next/image";
import Link from "next/link";

const CategoryBanner = () => {
  const [categories, setCategories] = useState({
    bigRamy: { image: null, name: '' },
    koGear: { image: null, name: '' },
    dragon: { image: null, name: '' },
    limitedEdition: { image: null, name: '' },
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const categoryIds = {
          bigRamy: 41,
          koGear: 42,
          dragon: 43,
          limitedEdition: 45,
        };

        const fetchedCategories = await Promise.all(
          Object.entries(categoryIds).map(async ([key, id]) => {
            const response = await axios.get(`https://partspluseg.com/public/api/Category/${id}`);
            return [key, {
              image: `https://partspluseg.com/${response.data.image}`,
              name: response.data.name,
            }];
          })
        );

        const categoriesData = fetchedCategories.reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});

        setCategories(categoriesData);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching category data:", err);
        setError(err);
        setLoading(false);
      }
    };

    fetchImages();
  }, []);

  if (loading) {
    return <div className="category-banner-area pt-30"><p>Loading categories...</p></div>;
  }

  if (error) {
    return <div className="category-banner-area pt-30"><p>Error loading categories.</p></div>;
  }

  return (
    <div className="category-banner-area pt-30">
      <div className="container">
        <div className="row">
          {/* BIG RAMY Banner */}
          <div className="col-xl-4 col-lg-6 col-md-6">
            <div className="category-banner-single mb-30 pos-rel">
              <Link href="/big-ramy">
                {/* Ensuring the Image is directly inside Link */}
                <div className="category-banner-img" style={{ cursor: 'pointer' }}>
                  <Image
                    src={categories.bigRamy.image}
                    alt={`${categories.bigRamy.name} banner`}
                    width={510}
                    height={630}
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
              </Link>
              <div className="category-banner-inner">
                <div className="category-banner-content">
                  <Link href="/big-ramy" className="product-category">
                    {categories.bigRamy.name}
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* DRAGON Banner */}
          <div className="col-xl-4 col-lg-6 col-md-6 order-xl-3">
            <div className="category-banner-single mb-30 pos-rel">
              <Link href="/dragon">
                <div className="category-banner-img" style={{ cursor: 'pointer' }}>
                  <Image
                    src={categories.dragon.image}
                    alt={`${categories.dragon.name} banner`}
                    width={510}
                    height={630}
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
              </Link>
              <div className="category-banner-inner">
                <div className="category-banner-content">
                  <Link href="/dragon" className="product-category">
                    {categories.dragon.name}
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* KO GEAR and Limited Edition Banners */}
          <div className="col-xl-4 order-xl-2">
            <div className="row">
              {/* KO GEAR Banner */}
              <div className="col-xl-12 col-lg-6 col-md-6">
                <div className="category-banner-single mb-30 pos-rel">
                  <Link href="/kogear">
                    <div className="category-banner-img" style={{ cursor: 'pointer' }}>
                      <Image
                        src={categories.koGear.image}
                        alt={`${categories.koGear.name} banner`}
                        width={510}
                        height={630}
                        style={{ width: "100%", height: "auto" }}
                      />
                    </div>
                  </Link>
                  <div className="category-banner-inner align-items-end">
                    <div className="category-banner-content">
                      <Link href="/kogear" className="product-category">
                        {categories.koGear.name}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* Limited Edition Banner */}
              <div className="col-xl-12 col-lg-6 col-md-6">
                <div className="category-banner-single mb-30 pos-rel">
                  <Link href="/limited-ed">
                    <div className="category-banner-img" style={{ cursor: 'pointer' }}>
                      <Image
                        src={categories.limitedEdition.image}
                        alt={`${categories.limitedEdition.name} banner`}
                        width={510}
                        height={630}
                        style={{ width: "100%", height: "auto" }}
                      />
                    </div>
                  </Link>
                  <div className="category-banner-inner justify-content-end">
                    <div className="category-banner-content">
                      <Link href="/limited-ed" className="product-category">
                        {categories.limitedEdition.name}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* End of KO GEAR and Limited Edition Banners */}
            </div>
          </div>
          {/* End of row */}
        </div>
      </div>
    </div>
  );
};

export default CategoryBanner;
