'use client';

import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper"; // Removed Navigation
import "swiper/css"; // Swiper core styles
import "swiper/css/pagination"; // Swiper pagination styles
import Link from "next/link";
import axios from "axios";

// Define TypeScript interface for Slider data
interface SliderType {
  id: number;
  title: string;
  description: string;
  image: string;
  order: number;
  is_active: string; // Assuming 'is_active' is a string based on API response
  link?: string; // Optional field if you have a link associated with the slider
}

const HeroBannerSec: React.FC = () => {
  const [sliderData, setSliderData] = useState<SliderType[]>([]); // State to store slider data from API
  const [loading, setLoading] = useState<boolean>(true); // State to handle loading
  const [error, setError] = useState<string | null>(null); // State to handle errors

  // Fetch slider data from the API
  useEffect(() => {
    const fetchSliderData = async () => {
      try {
        const response = await axios.get("https://partspluseg.com/public/api/sliders");
        // Filter active sliders and sort them by 'order' in ascending order
        const activeSliders: SliderType[] = response.data
          .filter((slide: SliderType) => slide.is_active === "1")
          .sort((a: SliderType, b: SliderType) => a.order - b.order);
        setSliderData(activeSliders);
      } catch (err) {
        console.error("Error fetching slider data:", err);
        setError("Failed to load slider data.");
      } finally {
        setLoading(false);
      }
    };

    fetchSliderData();
  }, []);

  if (loading) {
    return (
      <div className="hero_slider_wrapper p-relative banner-bg-1  pos-rel fix">
        <p>Loading sliders...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="banner-area hero_slider_wrapper p-relative banner-bg-1  pos-rel fix">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="hero_slider_wrapper p-relative banner-bg-1  pos-rel fix">
      <div className="slider__active">
        <Swiper
          modules={[Autoplay, Pagination]}
          spaceBetween={30}
          slidesPerView={1}
          loop={true}
          autoplay={{
            delay: 5000, // 5 seconds delay between transitions
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          className="hero-swiper"
        >
          {sliderData.map((slide) => (
            <SwiperSlide key={slide.id}>
              <div
                className="container hero-banner-slide"
                style={{
                  backgroundImage: `url(https://partspluseg.com/storage/${slide.image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  height: "100vh", // Adjust height as needed
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start", // Align content to the left
                  paddingLeft: "50px", // Add padding to position content
                }}
              >
                <div className="row align-items-center w-full">
                  <div className="col-xxl-12 col-xl-12 col-lg-12">
                    <div className="banner-content pos-rel mb-30 text-left"> {/* Ensure text is left-aligned */}
                      <span className="banner-subtitle">{slide.title}</span>
                      <div className="banner-btn">
                        <Link className="fill-btn-shop" href={slide.link}>
                             {slide.description}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-5 col-xl-5 col-lg-5">
                    <div className="banner-thumb-wrapper pos-rel">
                      {/* Add any additional elements or shapes here if needed */}
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        {/* Swiper Pagination */}
        <div className="slider-pagination slider1-pagination pagination_tabs"></div>
      </div>
    </div>
  );
};

export default HeroBannerSec;
