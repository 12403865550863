// components/home/CategoryArea.tsx
'use client';
import React, { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";

interface Tag {
  id: number;
  name: string;
  image: string;
  product_count: string;
}

const CategoryArea: React.FC = () => {
  const [tags, setTags] = useState<Tag[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await fetch("https://partspluseg.com/public/api/Tag");
        const data = await response.json();

        const filteredTags = data
          .filter((tag: any) => tag.id !== 1)
          .map((tag: any) => ({
            id: tag.id,
            name: tag.name,
            image: tag.image,
            product_count: tag.product_count,
          }));

        setTags(filteredTags);
      } catch (error) {
        console.error("Failed to fetch tags:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTags();
  }, []);

  return (
    <section className="category-area pt-90 pb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8">
            <div className="section-title text-center">
              <h2 className="section-main-title mb-50">Browse More</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="product-category-wrapper">
              {loading ? (
                <p>Loading...</p>
              ) : (
                <Swiper
                  modules={[Navigation]}
                  spaceBetween={20}
                  slidesPerView="auto" // Adjusts slides to fill available width
                  navigation
                  loop
                  breakpoints={{
                    640: { slidesPerView: 1 },
                    768: { slidesPerView: 2 },
                    1024: { slidesPerView: 3 },
                    1280: { slidesPerView: 4 },
                  }}
                  style={{
                    padding: '10px 0',
                  }}
                >
                  {tags.map((tag) => (
                    <SwiperSlide
                      key={tag.id}
                      style={{ width: '100%', maxWidth: '300px' }} // Ensures each slide is wider
                    >
                      <div className="product-category-single pos-rel" style={{ width: '100%' }}>
                        <div className="product-category-img" style={{ padding: '10px' }}>
                          <Link href={`/${tag.name}`}>
                            <Image
                              src={`https://partspluseg.com/${tag.image}`}
                              alt={tag.name}
                              width={300}
                              height={300}
                              className="w-100 h-auto"
                            />
                          </Link>
                        </div>
                        <div className="product-category-inner">
                          <div className="product-category-content text-center">
                            <Link href={`/${tag.name}`} className="product-category">
                              {tag.name}
                            </Link>
                            <div className="product-meta-item">
                              <div className="product-available">{tag.product_count}</div>
                              <div className="product-meta-type">products</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CategoryArea;
